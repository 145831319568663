/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Image, TitleMain, Text, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Subtitle, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu pb--02 pl--16 pr--16 pt--02" anim={null} menu={true} animS={null} style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null}>
            
            <Menu className="--full pb--06 pl--06 pr--06 pt--06" style={{"maxWidth":""}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box mb--0 mt--0 pb--0 pr--12 pt--0" style={{"maxWidth":130}}>
                
                <Image alt={""} src={"https://cdn.swbpg.com/t/11212/dded9918108b49d9a11caead32891b1a_s=860x_.jpg"} svg={false} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/11212/dded9918108b49d9a11caead32891b1a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/dded9918108b49d9a11caead32891b1a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/dded9918108b49d9a11caead32891b1a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/dded9918108b49d9a11caead32891b1a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/dded9918108b49d9a11caead32891b1a_s=2000x_.jpg 2000w"} content={null}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper className="menu-logo-box mt--02">
                
                <TitleMain className="title-box fs--22 w--600 swpf--uppercase pl--0" content={"<span style=\"color: rgb(0, 0, 0);\">Půjčovna dodávek</span>"}>
                </TitleMain>

                <Text className="text-box fs--14 w--900 swpf--uppercase ls--02 lh--16 mt--0 pl--0" content={"<span style=\"color: rgb(204, 62, 62);\">Ostravsko</span>"}>
                </Text>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 fs--16 w--500 swpf--uppercase pl--06 pr--06" innerClassName="" href={"#cenik"} tsbtn={null} target={""} content={"ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 fs--16 w--500 swpf--uppercase pl--06 pr--06" innerClassName="" href={"#technika"} tsbtn={null} target={""} content={"Dodávky"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 fs--16 w--500 swpf--uppercase pl--06 pr--06" innerClassName="" href={"#kontakt"} tsbtn={null} target={""} content={"kontakt"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn4 btn-box--shape5 btn-box--cColor2 fs--16 w--600 swpf--uppercase ls--01 pl--06 pr--0" innerClassName="" href={"#poptavka"} tsbtn={null} target={""} content={"Poptávka"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-glwnoo pb--0 pt--80" name={"uvod"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} border={null} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pt--40" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5 --center pb--20 pt--10" anim={"7"} animS={"5"} style={{"maxWidth":1360}}>
              
              <Title className="title-box fs--72 w--600 title-box--invert ls--004" content={"Půjčujeme&nbsp;<span style=\"background-color: rgb(204, 62, 62);\">&nbsp;dodávky,&nbsp;</span> na které<br>se můžete&nbsp;<span style=\"background-color: rgb(204, 62, 62);\">&nbsp;spolehnout.&nbsp;</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box ff--3 fs--26 w--500 subtitle-box--invert ls--001 mt--16" content={"Dodávky již od 899 Kč / den"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn5 btn-box--shape5 btn-box--cColor2 fs--20 w--600 swpf--uppercase mt--25" href={"#technika"} content={"naše dodávky"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape2 --shadow4 --center el--3 pb--0 pl--40 pr--40 pt--30" anim={null} style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center" anim={null} animS={null} style={{"maxWidth":360}}>
              
              <Image alt={"Ikonka půjčovna minibagru"} src={"https://cdn.swbpg.com/o/11212/bcebd04aba984219964d30e337aa5207.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":80}} srcSet={""} content={null} svgColor={"rgba(204,62,62,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--08" content={"<span style=\"color: rgb(0, 0, 0);\">Stroje v perfektní kondici</span>"}>
              </Subtitle>

              <Text className="text-box mt--12" style={{"maxWidth":""}} content={"Dodávky pravidelně čistíme, kontrolujeme a servisujeme."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" anim={null} animS={null} style={{"maxWidth":360}}>
              
              <Image alt={"Ikonka minibagr bez skrytých poplatků"} src={"https://cdn.swbpg.com/o/11212/dd7fe3b78dc244ada727f4db50edfc3f.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":80}} srcSet={""} content={null} svgColor={"rgba(204,62,62,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--08" content={"<span style=\"color: rgb(0, 0, 0);\">Až 500 km denního nájezdu zdarma</span>"}>
              </Subtitle>

              <Text className="text-box mt--12" style={{"maxWidth":232}} content={"U naší autopůjčovny se denní nájezdy sčítají.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" anim={null} animS={null} style={{"maxWidth":360}}>
              
              <Image alt={"Ikonka přeprava minibagru"} src={"https://cdn.swbpg.com/o/11212/6f2f21632bef4e1fb4248f2beeb2714b.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":80}} srcSet={""} content={null} svgColor={"rgba(204,62,62,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--08" content={"<span style=\"color: rgb(0, 0, 0);\">Stačí vám řidičák skupiny B</span><br>"}>
              </Subtitle>

              <Text className="text-box mt--12" style={{"maxWidth":""}} content={"Na všechny naše dodávky stačí řidičák jako na běžné osobní auto.\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--0" name={"technika"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex --shape2 --shadow4 --center el--3 pb--0 pl--40 pr--40 pt--40" style={{"backgroundColor":"rgba(255,255,255,1)"}} columns={"3"} fullscreen={false}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mt--60" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--left pb--10 pt--10" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--48" content={"Naše dodávky<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--40" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Image alt={"Půjčovna minibagrů – vrtací násada"} src={"https://cdn.swbpg.com/t/11212/faa442a0a4ab4343bc291b5f9f6cb542_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/faa442a0a4ab4343bc291b5f9f6cb542_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/faa442a0a4ab4343bc291b5f9f6cb542_s=660x_.jpg 660w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box fs--18 pl--0" content={"<span style=\"color: rgb(0, 0, 0);\">Malé dodávky</span>"}>
              </Subtitle>

              <Title className="title-box title-box--style3 fs--36 swpf--uppercase mt--06 pl--0" content={"od 899 Kč / den"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Image alt={"Půjčovna minibagrů – Kubota"} src={"https://cdn.swbpg.com/t/11212/8d5c6d54f4394a1fb6353515964ad7b6_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/8d5c6d54f4394a1fb6353515964ad7b6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/8d5c6d54f4394a1fb6353515964ad7b6_s=660x_.jpg 660w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box fs--18 pl--0" content={"<span style=\"color: rgb(0, 0, 0);\">Střední dodávky</span>"}>
              </Subtitle>

              <Title className="title-box title-box--style3 fs--36 swpf--uppercase mt--06 pl--0" content={"OD 999 KČ / DEN"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Image alt={"Půjčovna minidumperů"} src={"https://cdn.swbpg.com/t/11212/a89a51aaeb794eeb83852a237c1be9bc_s=660x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/a89a51aaeb794eeb83852a237c1be9bc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/a89a51aaeb794eeb83852a237c1be9bc_s=660x_.jpg 660w"} content={null} position={{"x":"-43.89592760180995%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box fs--18 pl--0" content={"<span style=\"color: rgb(0, 0, 0);\">Velké dodávky</span>"}>
              </Subtitle>

              <Title className="title-box title-box--style3 fs--36 swpf--uppercase mt--06 pl--0" content={"OD 1399 KČ / DEN"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cenik"} style={{"backgroundColor":"rgba(250, 251, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --justify el--2 pb--20 pt--20 flex--center" style={{"maxWidth":1470}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5" anim={"5"} animS={"5"} style={{"maxWidth":528}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(0, 0, 0);\">Nabízíme indiviudální ceny od</span>"}>
              </Subtitle>

              <Title className="title-box fs--72 ls--004 mt--02" content={" 899 Kč / den."}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5" anim={"4"} animS={"5"} style={{"maxWidth":528}}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--sbtn1 btn-box--shape5 btn-box--cColor2 btn-box--right fs--20 w--600 swpf--uppercase ls--01 pb--0 pt--0" innerClassName="" content={"Nezávazná poptávka"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-glwnoo --left --parallax pb--60 pt--60" name={"9yxso1n5lt8"} style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l3"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/95503923b8d3427685763329b64ffc9b_con=121_ove=101012x83__s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 pb--25 pt--25" style={{"maxWidth":1470}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box subtitle-box--style3" content={"Můžu si půjčit pouze na jeden den, i o víkendu, svátku?"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"Ano, můžete, jsme schopni Vám auto předat i přes víkend/STÁTNÍ svátek. Vždy je třeba se však předem dohodnout.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box subtitle-box--style3" content={"Můžu s autem do zahraničí, je to zpoplatněno?"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"Můžete kamkoliv, jen je potřeba předem nahlásit. VŠE JE BEZ PŘÍPLATKŮ."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim7 --anim-s5" anim={"7"} animS={"5"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box subtitle-box--style3" content={"Kdy a jak si půjčenou dodávku můžu vyzvednout?"}>
              </Subtitle>

              <Text className="text-box text-box--invert" content={"Především v pracovní dny od 7 do 16hodin, není problém se však domluvit na předání mimo pracovní dobu, případě i o víkendu, svátku, jak Vám vyhovuje.\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"poptavka"} style={{"backgroundColor":"rgba(204,62,62,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--12 pt--12">
              
              <Title className="title-box title-box--invert" content={"Poptávka"}>
              </Title>

              <Text className="text-box mt--12" style={{"maxWidth":740}} content={"<span style=\"color: rgb(193, 215, 235);\">Vyplňte nasledující formulář a my Vás budeme brzy kontaktovat.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5 mt--12" anim={"2"} animS={"5"}>
              
              <ContactForm className="--shape2 --style3 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Požadovaná technika","type":"textarea","required":true,"placeholder":"Jakou dodávku poptávate?"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt"} style={{"backgroundColor":"rgba(250, 251, 255, 1)"}} layout={"l1"} parallax={false}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pt--10 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26" content={"<span style=\"color: rgb(0, 0, 0);\">PŮJČOVNA DODÁVEK</span>"}>
              </Title>

              <Text className="text-box fs--16 w--600 swpf--uppercase ls--02 lh--16 mt--0" content={"<span style=\"color: rgb(204, 62, 62);\">OSTRAVSKO</span><br>"}>
              </Text>

              <Text className="text-box w--500 mt--16" content={"<span style=\"color: rgb(0, 44, 91);\">Ostrava, Česká Republika</span><br><span style=\"color: rgb(0, 44, 91);\">poptavka@</span>pujcovna-dodavek-ostravsko.cz<span style=\"color: rgb(0, 44, 91);\"><br></span>"}>
              </Text>

              <Text className="text-box fs--14 w--500 mt--16" content={"Partner webu: <a href=\"https://cisteni-dpf-ostrava.cz/\">čištění dpf Ostrava</a>&nbsp;| <a href=\"https://beskydservis.cz/servis-opel-frydek-mistek/\">autoservis Frýdek-Místek</a>&nbsp;| <a href=\"https://alu-dvere.cz/hlinikove-vchodove-dvere/\">hliníkové vchodové dveře</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0">
              
              <Text className="text-box text-box--center w--500 lh--16" style={{"maxWidth":361}} content={"Jsme specialisti v oblasti pronájmu dodávek.\n\nPůjčíme vám dodávky nejrůznějších typů a velikostí.\n\nRealizujeme půjčení vozidel i o víkendech a svátcích.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#cenik"} content={"Ceník"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#technika"} content={"naše dodávky"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#poptavka"} content={"Poptávka"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor2 btn-box--right fs--18 w--500 swpf--uppercase" href={"#poptavka"} content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--02 pt--02" name={"8jtjkytjmt6"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"Vytvořte si <a href=\"https://saywebpage.com/cs/\" style=\"color: rgb(80, 80, 80);\">vlastní webové stránky</a> – Saywebpage.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}